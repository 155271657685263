import React from 'react'
import AboutPage from '../AboutUs'

const About = () => {
    return (
        <>
           <AboutPage/>
        </>
    )
}

export default About
