import React from 'react'
import { Link } from 'react-router-dom'
import {Button} from '../../ButtonElements'
import { InfoConatiner,
         InfoWrapper,
        InfoRow,
        Column1,
        Column2,
        TextWrapper,
        TopLine,
        Heading,
        Subtitle,
        BtnWrap  ,
        ImgWrap,
        Img  } from './infoElements'

const InfoSection = ({lightBg,
     id,
    imgStart,
    topLine,
    lightText,
    headline,
    darkText,
    description,
    buttonLable ,
    img,
    alt,
    primary,
    dark,
    dark2, btn }) => {
    return (
        <>
            <InfoConatiner lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText= {lightText}>{headline}</Heading>
                            <Subtitle darkText = {darkText} >{description}</Subtitle>
                            <BtnWrap>
                              
                                <Button to= {btn}
                                    smooth = {true}
                                    duration = {500}
                                    spy ={true}
                                    exact = "true"
                                    offset = {-80}
                                    primary = {primary ? 1 : 0}
                                    dark = {dark ? 1 : 0}
                                    dark2 = {dark2 ? 1 : 0}
                                >{buttonLable} </Button>
                              
                            </BtnWrap> 
                        </TextWrapper>
                        </Column1>
                        <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>

            </InfoConatiner>
        </>
    )
}

export default InfoSection
