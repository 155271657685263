export const MenuItems = [
  {
    title: "Immigrate",
    path: "/immigrate",
    cName: "ddown-link",
  },
  {
    title: "Study Permit",
    path: "/study-permit",
    cName: "ddown-link",
  },
  {
    title: "Business Migration ",
    path: "/Business-immigration",
    cName: "ddown-link",
  },
  {
    title: "Family Sponsorship",
    path: "/familySponsor",
    cName: "ddown-link",
  },
  {
    title: "Work Permit",
    path: "/work-permit",
    cName: "ddown-link",
  },
  {
    title: "Refusal Appeals",
    path: "/refusal",
    cName: "ddown-link",
  },
  // {
  //     title: 'Fees',
  //     path:'/fees',
  //     cName:'dropdown-link'
  // },
  // {
  //     title: 'Latest News',
  //     path:'/news',
  //     cName:'dropdown-link'
  // }
];
