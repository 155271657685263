import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import about from "../../assets/images/canada3.jpg";
import { apiGet } from "../../utils/apis";

const TrandingNews = () => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  let halfOfArray;
  const getNews = async () => {
    const url = "news";
    const res = await apiGet(url);
    setData(res.news);
  };

  useEffect(() => {
    getNews();
  }, []);

  if (data) {
    halfOfArray = data.length / 2;
  }

  const handleNavigate = (id) => {
    navigate(`/news/${id}`);
    window.location.reload(true);
  };

  return (
    <div className="trading-content">
      <p className="trading-upper-title">TRENDING</p>
      {data &&
        data.slice(halfOfArray, halfOfArray + 6).map((item, idx) => {
          return (
            <div
              className="trading-news"
              onClick={() => handleNavigate(item._id)}
            >
              <p className="trading-title">{item.title}</p>
              <img src={`http://localhost:5000/static/${item.image}`} alt="" />
            </div>
          );
        })}
    </div>
  );
};

export default TrandingNews;
