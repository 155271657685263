import React from 'react'
import News from '../LatestNews'

const LatestNews = () => {
    return (
        <>
            <News/>
        </>
    )
}

export default LatestNews
