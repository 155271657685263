import React from 'react'
import CareerSection from '../careerSection'

const Career = () => {
    return (
        <>
            <CareerSection/>
        </>
    )
}

export default Career
