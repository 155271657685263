import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import NavContactInfo from "./components/navInfo";
import Homepage from "./components/pages/Homepage";
import Services from "./components/pages/Services";
import ContactUs from "./components/pages/ContactUs";
import About from "./components/pages/About";
import Family from "./components/pages/Family";
import Fees from "./components/pages/Fees";
import BusinessImigrattion from "./components/pages/BusinessImigrattion";
import Career from "./components/pages/Career";
import Immigrate from "./components/pages/Immigrate";
import StudyPermit from "./components/pages/studyPermit";
import Refusal from "./components/pages/Refusal";
import WorkPermit from "./components/pages/WorkPermit";
import LatestNews from "./components/pages/LatestNews";
import Appointment from "./components/pages/Appointment";
import Footer from "./components/footer/index.jsx";
import React from "react";
import NewsPreview from "./components/LatestNews/news-preview";

function App() {
  return (
    <>
      <NavContactInfo />
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Homepage />} />
        <Route path="/services" exact element={<Services />} />
        <Route path="/contact-us" exact element={<ContactUs />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/familySponsor" exact element={<Family />} />
        <Route path="/fees" exact element={<Fees />} />
        <Route
          path="/Business-immigration"
          exact
          element={<BusinessImigrattion />}
        />
        <Route path="/career" exact element={<Career />} />
        <Route path="/immigrate" exact element={<Immigrate />} />
        <Route path="/study-permit" exact element={<StudyPermit />} />
        <Route path="/refusal" exact element={<Refusal />} />
        <Route path="/work-permit" exact element={<WorkPermit />} />
        <Route path="/news" exact element={<LatestNews />} />
        <Route path="/appointment" exact element={<Appointment />} />
        <Route path="/news/:id" exact element={<NewsPreview />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
