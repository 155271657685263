import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import about from '../../assets/images/canada3.jpg';
import { apiGet } from '../../utils/apis';
import Newsteller from '../newsteller/newsteller';
import TrandingNews from './news-tranding';

const NewsPreview = () => {
  const { id } = useParams();

  const [data, setData] = useState('');

  const getData = async () => {
    const url = `news/${id}`;
    const res = await apiGet(url);
    setData(res.item);
  };
  useEffect(() => {
    getData();
  }, []);
  console.log(data);

  return (
    <div className="container">
      <div class="row">
        {data && (
          <div class="col-12 col-sm-6 col-md-8">
            <div className="row">
              <img src={`http://localhost:5000/static/${data.image}`} alt="" />
              <div className="d-flex flex-column justify-content-center p-4">
                <h2 className="text-justify mt-5 title-news">{data.title}</h2>
                <h4 className="news-sub-title">{data.subtitle}</h4>
              </div>
              <div className="ql-editor news-body">
                <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
              </div>
            </div>
          </div>
        )}
        <div class="col-6 col-md-4 d-flex justify-content-start align-items-start">
          <div className="row sidebar-news">
            <div className="d-flex flex-column justify-content-start align-items-start">
              <Newsteller />
              <TrandingNews />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPreview;
