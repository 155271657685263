import React from 'react'
import FeePage from '../fees'

const Fees = () => {
    return (
        <FeePage/>
    )
}

export default Fees
