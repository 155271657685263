import React from 'react'
import Contact from '../contactPage'

const ContactUs = () => {
    return (
        <Contact/>
    )
}

export default ContactUs
