import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import App from './App';
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'jquery/dist/jquery.min.js'
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'


ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
    ,
  document.getElementById('root')
);


