import React from "react";

const Newsteller = () => {
  return (
    <div>
      <div className="subscribe_box">
        <div className="icon">
          <img
            src="https://www.cicnews.com/wp-content/uploads/2020/08/maillogo.svg"
            width={75}
            height={69}
            alt
            data-lazy-src="https://www.cicnews.com/wp-content/uploads/2020/08/maillogo.svg"
            data-ll-status="loaded"
            className="entered lazyloaded"
          />
        </div>
        <p>Stay up to date with the latest news on Canadian Immigration.</p>
        <form
          method="POST"
          className="form-inline"
          action="https://evaluator.canadavisa.com/api/forms/47/submissions"
          onsubmit="return (function(event){ if(event.target.submitBtn.disabled){return false;} event.target.submitBtn.disabled = true; event.target.submitBtn.innerHTML = 'processing...'; return true;})(event);"
        >
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Enter your email here"
              required
              name="email"
              type="email"
            />
          </div>
          <button
            name="submitBtn"
            className="btn btn-primary mt-2 "
            type="submit"
          >
            Subscribe
          </button>
        </form>
      </div>
    </div>
  );
};

export default Newsteller;
