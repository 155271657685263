import React from 'react'
import Work from '../Workpermit'

const WorkPermit = () => {
    return (
        <>
            <Work/>
        </>
    )
}

export default WorkPermit
