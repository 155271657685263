import React from 'react'
import BusinessPage from '../Business'

const BusinessImigrattion = () => {
    return (
        <>
           <BusinessPage/>
        </>
    )
}

export default BusinessImigrattion
