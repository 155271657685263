import React from "react";
import "./newsbox.css";
import about from "../../assets/images/family.jpg";
import { useState } from "react";
import { apiGet } from "../../utils/apis/index";
import { useEffect } from "react";
import Newsteller from "../newsteller/newsteller";
import { useNavigate } from "react-router-dom";
import TrandingNews from "./news-tranding";
const NewsBox = () => {
  const [data, setData] = useState("");
  const navigate = useNavigate();

  //get news api
  const getNews = async () => {
    const url = "news";
    const res = await apiGet(url);
    const data = res.news;
    setData(data);
  };

  console.log(data);
  useEffect(() => {
    getNews();
  }, []);

  const arryLength = data.length;
  console.log(arryLength);
  console.log(data[arryLength]);
  const lastData = data[arryLength - 1];
  const secondLastData = data[arryLength - 2];
  const thirdLastData = data[arryLength - 3];

  const previewSingleNews = (id) => {
    navigate(`/news/${id}`);
  };

  return (
    <div className="main_card">
      <div className="container">
        <div className="row_flex">
          {lastData && (
            <div
              className="card_main"
              onClick={() => previewSingleNews(lastData._id)}
            >
              <img
                className="card_img"
                src={`http://localhost:5000/static/${lastData.image}`}
                alt=""
              />
              <div className="caption_box">
                <h3>{lastData.title}</h3>
                <p>{lastData.subtitle}</p>
              </div>
            </div>
          )}
          <div className="side_card">
            {secondLastData && (
              <div
                className="card"
                onClick={() => previewSingleNews(secondLastData._id)}
              >
                <img
                  className="card_img"
                  src={`http://localhost:5000/static/${secondLastData.image}`}
                  alt=""
                />
                <div className="side_card_catption_box">
                  <h3>{secondLastData.title}</h3>
                  <p>{secondLastData.subtitle}</p>
                </div>
              </div>
            )}
            {thirdLastData && (
              <div
                className="card"
                onClick={() => previewSingleNews(thirdLastData._id)}
              >
                <img
                  className="card_img"
                  src={`http://localhost:5000/static/${thirdLastData.image}`}
                  alt=""
                />
                <div className="side_card_catption_box">
                  <h3>{thirdLastData.title}</h3>
                  <p>{thirdLastData.subtitle}</p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="card-main-wrapper">
          <div>
            {data &&
              [...data]
                .slice(0)
                .reverse()
                .map((item, idx) => {
                  if (idx > 2) {
                    return (
                      <div
                        className="card-mai-content"
                        onClick={() => previewSingleNews(item._id)}
                      >
                        <div className="card-content-wrapper">
                          <h4>{item.title}</h4>
                          <p>{item.subtitle}</p>

                          <img
                            className="main-content-img"
                            src={`http://localhost:5000/static/${item.image}`}
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  }
                })}
          </div>

          <div className="aside-content">
            <Newsteller />
            <TrandingNews />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsBox;
