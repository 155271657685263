import React from 'react'
import Study from '../Study'

const studyPermit = () => {
    return (
        <>
          <Study/>
        </>
    )
}

export default studyPermit
