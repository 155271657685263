import React from 'react'
import Booking from '../booking'

const Appointment = () => {
    return (
       <Booking/>
    )
}

export default Appointment
