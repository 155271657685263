import React from 'react'
import Services from '../HomePageComponents/Service'
import HeroSlider from '../HomePageComponents/Slider/Slider'

const Homepage = () => {
    return (
        <>
            <HeroSlider/>
            <Services/>
        
        </>
    )
}

export default Homepage
