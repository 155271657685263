import React from 'react'
import FamilySponsor from '../familySponsor'

const Family = () => {
    return (
        <>
            <FamilySponsor/>
        </>
    )
}

export default Family
