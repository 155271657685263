import React from 'react'
import Immigration from '../ImmigrationPage'

const Immigrate = () => {
    return (
        <>
           <Immigration/>
        </>
    )
}

export default Immigrate
