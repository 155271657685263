import React from 'react'
import RefusalComponent from '../Refusal'

const Refusal = () => {
    return (
        <>
            <RefusalComponent/>
        </>
    )
}

export default Refusal
