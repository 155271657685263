import React from "react";
import "./elements.css";
import NewsBox from "./news-box";

const News = () => {
  return (
    <div className="news-container">
      <div className="new-wrapper">
        <NewsBox />
      </div>
    </div>
  );
};

export default News;
